<template>
    <div class="form-box">
        <el-form :model="searchForm">
            <el-row :gutter="25">
                <el-col :span="6">
                    <el-form-item label="业务员名称：">
                        <el-input v-model="searchForm.name" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="时间：">
                        <el-date-picker v-model="searchForm.date" type="datetimerange" range-separator="至"
                            start-placeholder="开始时间" end-placeholder="结束时间" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-button @click="search" type="primary">
                        <el-icon>
                            <Search />
                        </el-icon>
                        搜索
                    </el-button>
                    <el-button @click="exportFiel" type="primary">
                        导出
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="table-box">
        <div class="table-main">
            <c-table ref="saleamanProfitTableRef" :tableDataUrl="tableDataUrl" :isLoadData="false" :border="true">
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column prop="name" label="业务员名称" />
                <el-table-column prop="phone" label="业务员手机" />
                <el-table-column prop="number" label="交易笔数" />
                <el-table-column prop="pay_amount" label="支付金额" />
                <el-table-column prop="fee_amount" label="手续费" />
                <el-table-column prop="total_amount" label="总交易金额" />
                <el-table-column prop="refund_amount" label="退款金额" />
                <el-table-column prop="last_profit" label="手续费分润" />
            </c-table>
        </div>
    </div>
</template>

<script setup>
import { getCurrentInstance, ref, reactive,onMounted, nextTick } from 'vue'
import { timeFormat, getTodayStartAndEnds } from '@/func/time'
import { Search } from '@element-plus/icons-vue'
import cTable from '@/components/CustomTable'

const { proxy } = getCurrentInstance() // 当前实例

const defaultDate = getTodayStartAndEnds()
const searchForm = reactive({
    name: '',
    date: defaultDate
})

const tableDataUrl = proxy.$api.salesman.statistical


onMounted(() => {
    console.log('加载')
    search()
})

const saleamanProfitTableRef = ref(null)
const search = () => {
    nextTick(() => {
        if (searchForm.date && searchForm.date.length != 0) {
            searchForm.time_start = timeFormat(searchForm.date[0], 'datetime')
            searchForm.time_end = timeFormat(searchForm.date[1], 'datetime')
        }
        saleamanProfitTableRef.value.setCustomLoad(true)
        saleamanProfitTableRef.value.search(searchForm)
    })
}
const exportFiel = () => {
    let params = {
        'downloadFile': true
    }
    if (searchForm.date && searchForm.date.length != 0) {
        params['time_start'] = timeFormat(searchForm.date[0], 'datetime')
        params['time_end'] = timeFormat(searchForm.date[1], 'datetime')
    }

    const filename = '业务员交易统计数据-' + timeFormat('', 'string')
    proxy.$api.salesman.export(params, filename)
}

</script>
